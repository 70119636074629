
import { defineComponent, onMounted, ref } from "vue";
import { CancellationOfferModel } from "@/models";
import { restClient } from "@/store/actions";

export default defineComponent({
    props: {
        offerId: { type: Number, required: true }
    },
    emits: ["handleClose"],

    setup(props, { emit }) {
        const cancellationOffer = ref<CancellationOfferModel>({});
        onMounted(async () => {
            const response = await restClient.getJson<CancellationOfferModel>(
                `/api/admin/CancellationOffer/${props.offerId}`
            );
            if (response.data) {
                cancellationOffer.value = response.data;
            }
        });

        return {
            cancellationOffer
        };
    }
});
