import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row align-items-stretch text-center"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "col-12 p-3 mt-5" }
const _hoisted_7 = { class: "col-12 p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.cancellationOffer)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "col-12 p-3",
          innerHTML: _ctx.cancellationOffer.headerText
        }, null, 8, _hoisted_2),
        _createElementVNode("div", {
          class: "col-12 p-3",
          innerHTML: _ctx.cancellationOffer.description
        }, null, 8, _hoisted_3),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cancellationOffer.cancellationOfferImages, (image, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: idx,
            class: "col p-3"
          }, [
            (image.imageUrl)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: image.imageUrl,
                  alt: image.description || '',
                  class: "img-fluid"
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true),
            (image.description)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "font-weight-bold",
                  innerHTML: image.description
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true)
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            class: "btn btn-lg text-white font-weight-bold px-5 rounded-pill",
            style: _normalizeStyle({
                    fontSize: _ctx.cancellationOffer.yesButtonFontSize || 'xxx-large',
                    borderColor:
                        _ctx.cancellationOffer.yesButtonColor ?? 'transparent',
                    backgroundColor:
                        _ctx.cancellationOffer.yesButtonColor ?? 'transparent'
                })
          }, _toDisplayString(_ctx.cancellationOffer.yesButtonText), 5)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("a", {
            href: "#",
            class: "btn btn-lg col-5 font-weight-bold",
            style: _normalizeStyle({
                    fontSize: _ctx.cancellationOffer.noButtonFontSize || 'xxx-large',
                    color: _ctx.cancellationOffer.noButtonColor ?? 'transparent'
                })
          }, _toDisplayString(_ctx.cancellationOffer.noButtonText), 5)
        ])
      ]))
    : _createCommentVNode("", true)
}